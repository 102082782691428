import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import tw from 'tailwind.macro'
import styled from '@emotion/styled'

const Root = styled.div`
  ${tw`text-black`};

  p {
    ${tw`text-grey-darkest`};
  }

  a {
    ${tw`text-mushroom-tint`}

    &:hover {
      filter: saturate(150%);
    }
  }

  figure {
    margin: 0;
  }

  img {
    max-width: 100%;
    ${tw`shadow-md`}
  }
`

function SanityContentRenderer({ blocks }) {
  return (
    <Root>
      <BlockContent dataset="production" projectId="4i0atcrj" blocks={blocks} />
    </Root>
  )
}

export default SanityContentRenderer
