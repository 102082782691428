import { graphql } from 'gatsby'
import React from 'react'
import { ContainerSm } from '../components/container'
import { QualityGuaranteeFooter } from '../components/footer'
import Layout from '../components/layout'
import SanityContentRenderer from '../components/sanity-content-renderer'
import SEO from '../components/seo'

function AboutUsPage({ data }) {
  const page = data.page.edges[0].node
  const contentBlock = page._rawContentBlocks[0]
  return (
    <Layout FooterComponent={QualityGuaranteeFooter}>
      <SEO title={page.suffix} description={page.description} />
      <ContainerSm>
        <SanityContentRenderer blocks={contentBlock.content} />
      </ContainerSm>
    </Layout>
  )
}

export const query = graphql`
  query AboutUsPageQuery {
    page: allSanityPage(filter: { path: { eq: "/ueber-uns" } }) {
      edges {
        node {
          suffix
          description
          _rawContentBlocks
        }
      }
    }
  }
`

export default AboutUsPage
